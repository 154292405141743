<template>
  <div>
    <div class="rounded-md bg-yellow-50 sm:p-5 m-8">
      <div class="flex">
        <div class="flex-shrink-0">
          <!-- Heroicon name: exclamation -->
          <svg
            class="h-5 w-5 text-yellow-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
              fill-rule="evenodd"
            />
          </svg>
        </div>
        <div class="ml-3">
          <h3 class="text-sm leading-5 font-normal text-yellow-800">
            {{ $t("attention-needed") }}
          </h3>
          <div
            v-if="
              (whitelabel === 'TD365' ||
                whitelabel === 'TDSouthAfrica' ||
                whitelabel === 'OneTradingMarkets' ||
                whitelabel === 'EagleGlobalMarkets' ||
                whitelabel === 'TradeCoreUK') &&
              ($store.getters.get_user_data.profile_state === 99 ||
                $store.getters.get_user_data.profile_state === null)
            "
            class="mt-2 text-sm leading-5 text-yellow-700"
          >
            <p>
              {{
                $t(
                  "please-wait-while-we-review-your-application-if-it-takes-more-than--5b1585b89429ea2e73b4b5fc85ce86b1"
                )
              }}
            </p>
            <a
              class="text-indigo-600 cursor-pointer"
              @click="checkProfileState"
            >
              <span v-if="whitelabel === 'OneTradingMarkets'"
                >{{ $t("refresh") }}
              </span>
              <span v-else>Refresh</span>
            </a>
          </div>
          <div v-else>
            <div
              v-if="
                $store.getters.get_profile.state === 'Review' &&
                whitelabel !== 'TD365'
              "
              class="mt-2 text-sm leading-5 text-yellow-700"
            >
              <p>
                <!-- {{ $t('your-application-is-under-review-you-will-be-contacted-as-soon-as-it-has-been-processed') }}  -->
                {{
                  $t(
                    "your-application-is-pending-approval-please-upload-required-documents"
                  )
                }}
              </p>
              <router-link to="/upload-documents">
                <a class="text-indigo-600">{{ $t("click-here-to-upload") }}</a>
              </router-link>
            </div>
            <div v-else class="mt-2 text-sm leading-5 text-yellow-700">
              <p>
                <span v-if="whitelabel === 'TDSouthAfrica'">
                  We need more information to complete your application. We’ve
                  sent you an email with the details.
                </span>
                <span v-if="whitelabel === 'TD365'">
                  <span v-if="title">
                    Your application is under review, you will be contacted as
                    soon as it has been processed.
                  </span>
                </span>
                <template v-else>
                  <span v-if="title">
                    {{ $t(title) }}
                  </span>
                  <span v-else>
                    {{
                      $t(
                        "further-id-verification-required-to-progress-account-application"
                      )
                    }}
                  </span>
                  <router-link to="/upload-documents">
                    <a class="text-indigo-600">{{
                      $t("click-here-to-upload")
                    }}</a>
                  </router-link>
                </template>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CompleteIdIdentification",
  data() {
    return {
      whitelabel: process.env.VUE_APP_WHITELABEL,
    };
  },
  props: {
    title: {
      type: String,
      required: true,
      default: () => {
        return "";
      },
    },
  },
  mounted() {
    this.$store.dispatch("user_data");
  },
  methods: {
    checkProfileState() {
      this.$router.go(0);
      // this.$store.dispatch('user_data')
    },
  },
};
</script>
